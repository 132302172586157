import React from 'react';

interface DropdownProps {
    label: string;
    selectedValue: string;
    isDropdownOpen: boolean;
    toggleDropdown: () => void;
    handleSelect: (time: string) => void;
    timeOptions:string[]
    error?: string;
}

const Dropdown: React.FC<DropdownProps> = ({ label, selectedValue, isDropdownOpen, toggleDropdown, handleSelect,timeOptions,error }) => (
    <div className="col-lg-6">
        <div>
          
            <h4 className="fw-normal">{label}</h4>
            <div className="position-relative">
                <div                    
                    className="select-selected position-relative d-flex justify-content-between align-items-center"
                    onClick={toggleDropdown}  
                >
                    {selectedValue}
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
                        <path d="M1 1L6 6L11 1" stroke="#7B7B7B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>

                {isDropdownOpen && (
                    <div className={`${timeOptions.length>0 ? "pe-auto select-items position-absolute z-2" : ""}`}>
                        {timeOptions.map(time => (
                            <div key={time} className="option-item" onClick={() => handleSelect(time)}>
                                {time}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            {error && <div className="error-text">{error}</div>}
        </div>
    </div>
);

export default Dropdown;