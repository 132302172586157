import { Image, Button } from "./index";
import { BlackLogo, NotificationIcon } from "../../assets/images/userDashboard";
import useToggle from "../../hooks/useToggle";
import { useAuthContext } from "../../context/AuthContext";
import { useNavigate, Link } from "react-router-dom";
import { User } from "../../types/authProvider";


const Header = () => {
    const [toggle, setToggle] = useToggle(false);
    const { logout } = useAuthContext();
    const navigate = useNavigate();

    let initials
    const user: User | null = JSON.parse(localStorage.getItem("user") || "null");
    if (user) initials = user ? `${user.first_name?.[0]?.toUpperCase() || ''}${user.last_name?.[0]?.toUpperCase() || ''}` : '';



    const handleLogout = () => {
        logout();
        navigate("/login")
    }

    return (
        <header className="header-section">
            <div className="d-flex align-items-center justify-content-between">
                <Link to='/dashboard' className="d-block">
                    <picture>
                        <Image src={BlackLogo} alt="black logo" className="img-fluid" />
                    </picture>
                </Link>
                <div className="d-flex align-items-center gap-4">
                   <div className="position-relative me-2 notification">
                   <a href="#" className="d-block">
                            <Image src={NotificationIcon} alt="notification" className="img-fluid" />
                    </a>
                    {/* <span className="notification-badge">1</span> */}
                   </div>
                    <div className="dropdown">
                        <Button className="btn btn-secondary fw-semibold border-0" type="button" data-bs-toggle="dropdown"
                            aria-expanded="false" onClick={setToggle}>{initials}</Button>
                        {toggle && <ul className="dropdown-menu show p-0">
                            {/* <li className="dropdown-item">Profile</li> */}
                            <li className="dropdown-item" onClick={handleLogout}>Logout</li>
                        </ul>}
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header