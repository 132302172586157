import { FC, ReactNode, MouseEvent } from 'react';

interface ButtonProps {
    type: 'button' | 'submit' | 'reset';
    className: string;
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
    children?: ReactNode;
    [x: string]: any;
}

const Button: FC<ButtonProps> = ({ type, className, onClick, children, ...props }) => {
    return (
        <button type={type} className={className} onClick={onClick} {...props}>
            {children}
        </button>
    );
};

export default Button;
