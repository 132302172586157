import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import axiosInstance from "../service/axiosInterceptor";
import { AuthContextType, User, UserLoginPayload } from '../types/authProvider';
import { Toast } from '../utils/toast';


const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }
    }, []);

    const login = async (user: UserLoginPayload) => {
        try {
            const { data } = await axiosInstance.post("/login", user);
            if (data.status) {
                const { user: userData, session } = data.data;
                setUser(userData);

                // Store session token and user data in localStorage
                localStorage.setItem('sessionToken', session.session_token);
                localStorage.setItem('user', JSON.stringify(userData));
                return true;
            }
            console.log(data)
            return false;
        } catch (error: any) {
            if (error.response) {
                console.error("Login error:", error.response.data.message || "Unknown error");
                Toast("error", error.response.data.message || "Unknown error")
            } else {
                console.error("Login error:", error.message);
                Toast("error", error.message || "Unknown error")
            }
            return false;
        }
    };


    const logout = () => {
        setUser(null);
        localStorage.removeItem('user');
        localStorage.removeItem('sessionToken');
    };

    const isAuthenticated = () => !!localStorage.getItem('user');;


    return (
        <AuthContext.Provider value={{ user, setUser, login, logout, isAuthenticated }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuthContext = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuthContext must be used within an AuthProvider');
    }
    return context;
};
