import axiosInstance from "./axiosInterceptor";
import { User } from "../components/signUp/SignUpCard";

export const signUp = async (userData: User) => {
  try {
    const { data } = await axiosInstance.post("/signup", userData);
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
