import { useState } from "react";
import { Button } from "../common";
import GoogleAuth from "../Login/GoogleAuth";
import { GoogleOAuthProvider, } from '@react-oauth/google';
import { GOOGLE_OAUTH_CLIENT_ID } from "../../constant";
import { useForm, SubmitHandler } from "react-hook-form";
import { signUp } from "../../service/signupSerice";
import { Toast } from "../../utils/toast";
import { useAuthContext } from "../../context/AuthContext";
import { useNavigate,Link } from "react-router-dom"

export interface User {
    first_name: string;
    last_name: string;
    username: string;
    email: string;
    password: string;
}

const SignUpCard = () => {
    const clientId = GOOGLE_OAUTH_CLIENT_ID;
    const { setUser } = useAuthContext()
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, formState: { errors },watch } = useForm<User>();
    const navigate = useNavigate();
    const firstName = watch("first_name");
    const lastName = watch("last_name");
    const username = watch("username");
    const email=watch("email");
    const password=watch("password");

    const onSubmit: SubmitHandler<User> = async (data: User) => {
        setLoading(true)
        try {
            const result = await signUp(data);
            if (result.status) {
                const { user: userData, session } = result.data;
                setUser(userData);

                // Store session token and user data in localStorage
                localStorage.setItem('sessionToken', session.session_token);
                localStorage.setItem('user', JSON.stringify(userData));
                navigate("/dashboard");
            }
        } catch (error: any) {
            if (error.response) {
                console.error("Login error:", error.response.data.message || "Unknown error");
                Toast("error", error.response.data.message || "Unknown error")
            } else {
                console.error("Login error:", error.message);
                Toast("error", error.message || "Unknown error")
            }
        } finally {
            setLoading(false)
        }
    };


    return (
        <div className="login-form">
            <span className="fw-normal">Sign up</span>
            <h2 className="fw-semibold mb-4 pb-xxl-3">Hello! Welcome back.</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3 pb-2">
                    <label htmlFor="firstName" className="form-label fw-normal">First Name*</label>
                    <input
                        {...register("first_name", { required: "First name is required." })}
                        type="text"
                        className={`form-control ${errors.first_name ? 'is-invalid' : ''}`}
                        id="firstName"
                        placeholder="Enter first name"
                    />
                    {errors.first_name && <div className="error-message">{errors.first_name.message}</div>}
                </div>
                <div className="mb-3 pb-2">
                    <label htmlFor="lastName" className="form-label fw-normal">Last Name*</label>
                    <input
                        {...register("last_name", { required: "last name is required." })}
                        type="text"
                        className={`form-control ${errors.last_name ? 'is-invalid' : ''}`}
                        id="lastName"
                        placeholder="Enter last name"
                    />
                    {errors.last_name && <div className="error-message">{errors.last_name.message}</div>}
                </div>
                <div className="mb-3 pb-2">
                    <label htmlFor="username" className="form-label fw-normal">Username*</label>
                    <input
                        {...register("username", { required: "username is required." })}
                        type="text"
                        className={`form-control ${errors.username ? 'is-invalid' : ''}`}
                        id="username"
                        placeholder="Enter username"
                    />
                    {errors.username && <div className="error-message">{errors.username.message}</div>}
                </div>
                <div className="mb-3 pb-2">
                    <label htmlFor="email" className="form-label fw-normal">Email address*</label>
                    <input
                        {...register("email", { required: "Email is required." })}
                        type="email"
                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                        id="email"
                        placeholder="Enter email address"
                    />
                    {errors.email && <div className="error-message">{errors.email.message}</div>}
                </div>
                <div className="mb-3 pb-2">
                    <label htmlFor="password" className="form-label">Password*</label>
                    <input
                        {...register("password", { required: "Password is required." })}
                        type="password"
                        className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                        id="password"
                        placeholder="Enter Password"
                    />
                    {errors.password && <div className="error-message">{errors.password.message}</div>}
                </div>

                <Button type="submit" className={`btn-primary fw-medium w-100 border-0 mb-4 login-btn ${firstName&&username&&lastName&&email&&password?"opacity-100":"opacity-50"}`} disabled={loading}>
                    {loading ? "Signing up..." : "Sign Up"}
                </Button>
                <p className="m-0 text-center">By signing in, you agree to our <a href="#">Terms & Conditions</a></p>
                <div className="my-4 py-md-1 position-relative text-center or-text">
                    <p className="m-0 d-inline-block">or</p>
                </div>
                <div className="text-center google-login">
                    <GoogleOAuthProvider clientId={clientId}>
                        <GoogleAuth />
                    </GoogleOAuthProvider>
                </div>
                <p className="mt-4 pt-md-1 m-0 text-center">Already Have An account? <Link to="/login">Login</Link></p>
            </form>
        </div>
    )
}

export default SignUpCard