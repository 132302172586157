import React, { FC, Suspense } from "react";
import { Home, Login, NotFound, SignUp } from "./pages";
import { Loader } from "./components/common";
import ProtectedRoute from "./ProtectedRoute";


interface RouteConfig {
  path: string;
  element: React.ReactElement
}


const Dashboard = React.lazy(() => import("./pages/Dashboard/Dashboard"));
const InterviewScreen = React.lazy(() => import("./pages/interviewScreen/InterviewScreen"));

const SuspendedDashboard: FC = () => (
  <Suspense fallback={<div><Loader /></div>}>
    <Dashboard />
  </Suspense>
);

const SuspenseInterviewScreen: FC = () => (
  <Suspense fallback={<div><Loader /></div>}>
    <InterviewScreen />
  </Suspense>
);

export const routes: RouteConfig[] = [
  { path: "/", element: <ProtectedRoute element={<Home />} /> },
  { path: "/login", element: <Login /> },
  { path: "/dashboard", element: <SuspendedDashboard /> },
  { path: "*", element: <NotFound /> },
  { path: "/SignUp", element: <SignUp /> },
  { path: "/interviewScreen", element: <SuspenseInterviewScreen /> }
];
