import React from 'react';
import { useGoogleLogin, TokenResponse } from '@react-oauth/google';
import { Button, Image } from '../common';
import { GoogleIcon } from "../../assets/images/login";// Replace with the correct path to your Google icon
import axios from 'axios';
import axiosInstance from '../../service/axiosInterceptor';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../context/AuthContext';

const GoogleAuth: React.FC = () => {
    const navigate = useNavigate();
    const { setUser } = useAuthContext();

    const handleLoginSuccess = async (tokenResponse: TokenResponse) => {
        if (tokenResponse) {
            try {
                const { access_token } = tokenResponse;

                const userData = await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${access_token}`, {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                        Accept: 'application/json'
                    }
                });

                if (userData) {
                    const { email, name, given_name, family_name } = userData.data;
                    const { data } = await axiosInstance.post('/oauth-login', {
                        email,
                        username: name,
                        first_name: given_name,
                        last_name: family_name
                    })
                    if (data) {
                        const { user: userData, session } = data.data;
                        setUser(userData)
                        localStorage.setItem('sessionToken', session.session_token);
                        localStorage.setItem('user', JSON.stringify(userData));
                        navigate('/dashboard')
                    }
                }

                console.log('User Data:', userData.data);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        }
    };

    const handleLoginFailure = (error: any) => {
        console.log('Login Failed:', error);
    };

    const login = useGoogleLogin({
        onSuccess: handleLoginSuccess,
        onError: handleLoginFailure,
    });

    return (
        <Button
            type="button"
            className="w-100 d-flex align-items-center justify-content-center gap-3 border-0 bg-transparent img-fluid"
            onClick={() => login()}
        >
            <Image src={GoogleIcon} alt="google" width={30} height={30} className="img-fluid" />
            Login With Google
        </Button>
    );
};

export default GoogleAuth;
