import React from 'react';

interface ModalProps {
    heading: string;
    message: string;
    onButtonClick: () => void;
}

const Modal: React.FC<ModalProps> = ({ heading, message, onButtonClick }) => {
    return (
        <div className="custom-modal-overlay z-2 position-fixed">
            <div className="invitation-send">
                <div className="custom-modal-body">
                    <div className='row'>
                        <div className='col-12'>
                            <div className='d-flex align-items-center justify-content-center flex-column gap-4'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="139" height="140" viewBox="0 0 139 140" fill="none">
                                    <path d="M134.5 64.0572V70.0372C134.492 84.0539 129.953 97.6926 121.561 108.919C113.168 120.146 101.371 128.358 87.9298 132.333C74.4883 136.307 60.1222 135.83 46.974 130.972C33.8259 126.114 22.6002 117.137 14.9712 105.378C7.34223 93.6194 3.71864 79.7096 4.6409 65.7232C5.56316 51.7369 10.9818 38.4233 20.0888 27.7682C29.1958 17.113 41.5031 9.68719 55.1752 6.59815C68.8473 3.50912 83.1517 4.92239 95.955 10.6272" stroke="#079B6D" stroke-width="8.96552" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M134.492 18.0449L69.4922 83.1099L49.9922 63.6099" stroke="#079B6D" stroke-width="8.96552" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <div className='invitation-success text-center'>
                                    <h2 className='fw-semibold mb-0'>{heading}</h2>
                                    <p className='mb-0'>{message}</p>
                                </div>
                                <button className='go-to-btn fw-medium' onClick={onButtonClick}>Return to Dashboard</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Modal;
